<template>
  <div>
    <Defaultlayout :userprofile="userprofile">
      <div class="modal fade repeat-alert-modal" id="UpdateProfilePic" tabindex="-1" role="dialog"
        aria-labelledby="UpdateProfilePic" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="UpdateProfilePic">
                Upload Profile Picture
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <vueShapeImg v-show="imgSrcObj != null && imgSrcObj != undefined && imgSrcObj != ''" @error="imgError"
                :useFrame="true" :timelyImageData="true" @imageDataChange="putImg2" ref="vueShapeImg2"></vueShapeImg>
              <img v-show="imgSrcObj == null || imgSrcObj == undefined || imgSrcObj == ''" class="profilePicUpdate"
                :src="getprfleimage" />
              <div class="profile-btns d-flex flex-wrap justify-content-center">
                <button v-show="imgSrcObj != null && imgSrcObj != undefined && imgSrcObj != ''" type="button"
                  :style="colorObj" @click="getImg2" class="btn hoverEffect">Crop</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="profile-section pt-0">
        <div class="container">
          <!-- banner start -->

          <div class="cover-photo profile-cover-photo">
            <img src="../../assets/images/profile-banner.jpg" alt="profile-banner" class="img-fluid" />
            <div class="cover-photo-changing photo-replace">
            </div>
            <div @click="editProfile()" :style="colorObj" class="btn btn-yellow edit-profile hoverEffect"
              data-toggle="modal" data-target="#editprofile">
              <img src="../../assets/images/svgs/photo-change.svg" alt="camara" class="mr-2" />Edit Profile
            </div>

            <!-- Modal for edit profile  -->
            <div class="modal fade" id="editprofile" ref="edit_profile" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body" v-if="loading == false">
                    <button @click="closePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="page-heading text-center">Edit Profile</h2>

                    <div class="profile-photo text-center" v-if="profiledata != null || profiledata != undefined">
                      <div class="photo">
                        <img :src="getprfleimage" v-if="getprfleimage != ''" alt="ptofile-photo" class="photoprfle"
                          width="150" height="150" />
                        <img src="../../assets/images/ptofile-photo.jpg" alt="ptofile-photo"
                          v-show="image == '' && getprfleimage == ''" width="150" height="150" />
                      </div>
                      <div class="edit-img-button">
                        <!-- <img src="../../assets/images/svgs/photo-change.svg" alt="photo-change"> -->
                        <button :style="buttonColor" @click="openProfilePicModal()" class="" ref="file">Edit Profile Image</button>
                      </div>
                      <div v-if="getprfleimage" class="profile-photo-changing photo-delete" :style="buttonColor">
                        <img src="../../assets/images/svgs/delete-icon.svg" alt="photo-change">
                        <button @click="deleteProfilePic()" class="hide_file" ref="file"></button>
                    </div>
                      <span class="help is-danger fileuplderr">Please upload only image file.</span>
                      
                    </div>
                    
                    

                    <div class="bottom-section">
                      <div class="alert alert-success" role="alert" v-show="enablesucmessage">{{ successmessage }}</div>
                      <div class="alert alert-danger" role="alert" v-show="enableerrmessage">{{ errormessage }}</div>
                      <form class="address-form form-horizontal animated-form" method="post"
                        enctype="multipart/form-data">
                        <div class="form-row">
                          <div class="form-group col-md-12 active">
                            <label :style="textcolor" class="control-label" for="prflename">
                              Name
                              <span class="helpmsg">*</span>
                            </label>
                            <input type="text" class="form-control" id="prflename" name="prflename" v-model="prflename"
                              v-validate="'required'" :class="{ error: errors.has('prflename') }" />
                            <span v-show="errors.has('prflename')" class="help is-danger">{{ $entrvalidname }}</span>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-md-12 active">
                            <label :style="textcolor" class="control-label" for="prflephoneno">Phone Number</label>
                            <input type="text" class="form-control lokalyprofilephoneno" id="prflephoneno"
                              name="prflephoneno" v-model="prflephoneno" v-validate="'required||digits:10'"
                              :class="{ error: errors.has('prflephoneno') }" readonly />
                            <span v-show="errors.has('prflephoneno')" class="help is-danger">{{ $entrvalidphone
                              }}</span>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-md-12 active">
                            <label :style="textcolor" class="control-label" for="prfleemail">
                              Email
                              <span class="helpmsg">*</span>
                            </label>
                            <div class="email-verified-profile pt-1">
                              <input type="email" class="form-control" id="prfleemail" name="prfleemail"
                                v-model="prfleemail" v-validate="'required'"
                                :class="{ error: errors.has('prfleemail') }"
                                @keydown="myFunction()" />
                              <!-- :readonly="is_email_verified == 1"  -->
                              <span v-if="!is_email_verified" @click="verifyEmail()"
                                title="Click to generate OTP for email verfication" style="cursor: pointer;">
                                <img id="unverifyemail" src="../../assets/images/svgs/unverified_icon.svg">
                              </span>
                              <span v-else>
                                <img src="../../assets/images/svgs/verified_icon.svg">
                              </span>
                            </div>
                            <span v-show="errors.has('prfleemail')" class="help is-danger">{{ $entrvalidemail }}</span>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group text-center col-md-12">
                            <button @click="validateProfile()" class="btn add-btn hoverEffect"
                              :style="colorObj">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="modal-body" v-if="loading == true">
                    <div class="container">
                      <p class="text-center">
                        <img src="../../assets/images/loading.gif" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for edit profile  closed-->
          </div>

          <!-- banner end -->
          <div class="bottom-section">
            <!-- my profile -->
            <div class="my-profile" v-if="userprofile != null && profiledata != null">
              <div class="main-profile-id media align-items-start align-items-md-end">
                <div class="profile-pic mr-3 mr-xl-4">
                  <img v-if="profiledata.user_picture != ''" :src="profiledata.user_picture" alt="..." />
                  <avatar :color="avatarBackgroundColor" :fullname="avatarText" :size="180"
                    v-if="profiledata.user_picture == ''"></avatar>
                </div>
                <div class="media-body pb-3">
                  <h2 class="section-heading mb-2">{{ profiledata.name }}</h2>
                  <a :href="'tel:' + profiledata.phone" class="d-block tel">{{ profiledata.phone }}</a>
                  <a :href="'mailto:' + profiledata.email" class="d-block email">{{ profiledata.email }}</a>
                  <a class="location d-block" href="#">
                    <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png" />
                    {{ user_address }}
                  </a>
                </div>
              </div>
            </div>
            <!-- my profile end-->

            <div class="tabs">
              <b-tabs content-class="mt-3">
                <b-tab title="Address Book" expanded @click="doAlert('/profile#address-book')"
                  :active="$route.hash === '#address-book'">
                  <Addressbook></Addressbook>
                </b-tab>
                <b-tab title="My Orders" expanded @click="doAlert('/profile#my-order')"
                  :active="$route.hash === '#my-order'">
                  <Myorder></Myorder>
                </b-tab>
                <b-tab v-if="khataactive == 1" expanded @click="doAlert('/profile#my-khata')" :title="`${khatalabel}`"
                  :active="$route.hash === '#my-khata'">
                  <Khata></Khata>
                </b-tab>
                <b-tab title="Wallet" expanded @click="doAlert('/profile#wallet')" v-if="wallet == 1"
                  :active="$route.hash === '#wallet'">
                  <Wallet></Wallet>
                </b-tab>
                <b-tab v-if="wishlist == 1" expanded @click="doAlert('/profile#wishlist')" title="Wishlist"
                  :active="$route.hash === '#wishlist'">
                  <Wishlist></Wishlist>
                </b-tab>
                <b-tab title="Notifications" expanded @click="doAlert('/profile#notification')"
                  :active="$route.hash === '#notification'">
                  <Notification></Notification>
                </b-tab>
              </b-tabs>
            </div>
            <!-- tabs section end -->
          </div>
        </div>
      </section>
    </Defaultlayout>
  </div>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import Addressbook from "../address-book/Addressbook";
import Khata from "../khata/Khata";
import Myorder from "../order/Myorder";
import Notification from "../user/Notification";
import Wallet from "../wallet/Wallet";
import Avatar from "vue-avatar-component";
import { bus } from '../../main';
import helper from "../../store/helper";
import Wishlist from '../wishlist/Wishlist';
import Swal from 'sweetalert2';
// import $ from 'jquery';
export default {
  name: "Profile",
  components: {
    Defaultlayout,
    Addressbook,
    Khata,
    Myorder,
    Notification,
    Avatar,
    Wallet,
    Wishlist
  },
  mixins: [helper],
  data() {
    return {
      avatarBackgroundColor: "",
      avatarTextColor: "",
      profile: {
        user_id: ""
      },
      location_hash: '',
      userprofile: null,
      user_address: "",
      loctntype: "",
      cityname: "",
      enablesucmessage: false,
      successmessage: "",

      successadrsmessage: "",
      enablesucsaveadrsmsg: false,
      successsaveadrsmsg: "",
      enableerrmessage: false,
      errormessage: "",

      customerprofile: {
        user_id: "",
        name: "",
        phone: "",
        email: "",
        user_picture: "",
        is_image_delete: ""
      },
      image: "",
      profiledata: null,
      prflename: "",
      prflephoneno: "",

      prfleemail: "",
      prflepasswrd: null,
      prfleconfrmpass: null,
      prfleimage: "",
      dbprfleimage: "",
      getprfleimage: "",
      user_picture: "",
      cities: [],
      getarea: "",
      passwordFieldType: "password",
      confirmpasswordFieldType: "password",
      loading: false,
      khatalabel: '',
      khataactive: '',
      wishlist: '',
      wallet: '',
      canvas2: null,
      canvas2Ctx: null,
      imgSrcObj: '',
      currentUrl: '',
      verify_email: {
        user_id: '',
        source: '',
      },
      resendcodeflg: false,
      is_email_verified: "",
    };
  },
  computed: {
    avatarText() {
      var str1 = this.profiledata.name.split(" ")[0];
      var str2 = this.profiledata.name.split(" ")[1];
      return str1 + " " + str2;
    }
  },
  mounted() {
    this.currentUrl = window.location.href;
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      this.user_address = this.getFilteredUserAddress(this.userprofile);
    } else {
      this.userprofile = null;
    }
    console.log("profile");
    window.$("#unverifyemail").show();

    this.location_hash = location.hash;
    setTimeout(function () {
      if (location.hash == "#profile") {
        window.$("#editprofile").modal("show");
      }
    }, 3000);
    this.getUserAddress();
    bus.$on("setDefaultAddress", () => {
      this.getUserAddress();
    });
    this.loading = false;

    bus.$on("globalsetting", data => {
      this.wishlist = JSON.parse(data).wishlist;
      this.wallet = JSON.parse(data).allow_wallet;
      this.khatalabel = JSON.parse(data).khata_label;
      this.khataactive = JSON.parse(data).allow_khata;
    });

    bus.$on("email_verified_data", data => {
      this.is_email_verified = data
    });
    this.getProfile();
    this.originalColor();
    if (this.currentUrl.indexOf("profile#wallet") > -1) {
      localStorage.setItem("current_url_wallet", this.currentUrl);
      this.$router.push("profile#wallet");
    }
    document.body.classList.remove('wishlist-top-bar');
   
  },
  updated() {
    window.$('.profile-section .avatar table tbody tr td').css({ color: localStorage.getItem("css_button_customer_default_font") });
    this.avatarBackgroundColor = this.buttonColor.backgroundColor;
    this.avatarTextColor = this.buttonColor.color;
  },
  methods: {
    openProfilePicModal() {
      this.$refs.vueShapeImg2.fileClick();
    },
    deleteProfilePic(){
      Swal.fire({
        title: "Are you sure?",
        text: "Delete!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(65, 35, 94)",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.getprfleimage = ''; 
        }
      });
    },
    doAlert(evtObj) {
      this.$router.push(evtObj);
    },
    putImg2() {
      this.imgSrcObj = "imgput";
      if (this.canvas2Ctx != null && this.canvas2Ctx != undefined)
        this.canvas2Ctx.clearRect(0, 0, 500, 500)
      window.$('#UpdateProfilePic').modal('show');
    },
    getImg2() {
      if (this.$refs.vueShapeImg2 != undefined) {
        this.imgSrc = this.$refs.vueShapeImg2.getImg('base64', 'image/jpeg', 0.7);
        this.$refs.vueShapeImg2.setImgSrc(this.imgSrc)
        this.getprfleimage = this.imgSrc;
        window.$('#UpdateProfilePic').modal('hide')
      }
    },
    imgError(error) {
      console.error(error)
    },
    getUserAddress() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if (result.status == 1) {
          var default_address = this.getDefaultAddress(result.data);
          this.user_address = this.getFilteredUserAddress(default_address[0]);
        }
      });
    },
    getDefaultAddress(user_address) {
      return user_address.filter(
        function (elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user) {
      var tmp_user_address = [];
      tmp_user_address.push(user.address, user.address2, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });

      if (filtered_user_address.length > 0) {
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    confirmswitchVisibility() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        files[0].type == "image/jpeg" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/bmp" ||
        files[0].type == "image/png" ||
        files[0].type == "image/gif" ||
        files[0].type == "image/tiff"
      ) {
        window.$(".fileuplderr").hide();
        this.createImage(files[0]);
        this.getprfleimage = files[0];
      } else {
        window.$(".fileuplderr").show();
      }
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.getprfleimage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getProfile() {
      this.profile.user_id = (localStorage.getItem("user") != null)
        ? JSON.parse(localStorage.getItem("user")).user_id
        : "0";

      this.$store.dispatch("profiledetails", this.profile).then(response => {
        if (response.status == 1) {
          this.profiledata = JSON.parse(JSON.stringify(response.data));
          this.prflename = this.profiledata.name;
          this.prflephoneno = this.profiledata.phone;
          this.prfleemail = this.profiledata.email;
          this.dbprfleimage = this.profiledata.user_picture;
          this.getprfleimage = this.profiledata.user_picture;
          this.prflepasswrd = null;
          this.is_email_verified = this.profiledata.is_email_verified
          window.$("#unverifyemail").show();
          window.$(this.$refs.edit_profile).on("show.bs.modal", this.EditProfileShow);
        }
      });
    },
    EditProfileShow() {
      this.imgSrc = "";
      this.getprfleimage = this.profiledata.user_picture;
    },
    ConvertFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    validateProfile() {
      if (this.$validator.errors.items.length > 0) {
        if (this.$validator.errors.items[0] == "The file field must be a valid file") {
          this.$toast.error("Upload JPG, JPEG, BMP, PNG, GIF, or TIFF for profile image")
          return;
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          this.customerprofile.user_id = (localStorage.getItem("user") != null)
            ? JSON.parse(localStorage.getItem("user")).user_id
            : "0";
          this.customerprofile.name = this.prflename;
          this.customerprofile.phone = this.prflephoneno;
          
          if(!this.getprfleimage){
            this.customerprofile.is_image_delete = 1;
            this.customerprofile.user_picture = ''
          } else {
            this.customerprofile.is_image_delete = 0;
            if (this.imgSrc != null && this.imgSrc != undefined && this.imgSrc != '') {
            var convertedImage = this.ConvertFile(this.imgSrc, 'data.png');
              this.customerprofile.user_picture = convertedImage != undefined ? convertedImage : '';
            }
          }
          this.customerprofile.email = this.prfleemail;
          this.$store
            .dispatch("customermanageprofile", this.customerprofile)
            .then(response => {
              this.loading = false;

              if (JSON.parse(JSON.stringify(response)).status == "1") {
                localStorage.setItem("user", JSON.stringify(response.data));
                this.enablesucmessage = true;
                this.successmessage = JSON.parse(JSON.stringify(response)).message;
                setTimeout(() => (this.enablesucmessage = false), 3000);
                bus.$emit('new', JSON.stringify(response.data));
                // window.$("#editprofile").modal("hide");
                window.$('.modal-backdrop').hide();
                this.$toast.success("Profile updated successfully");
                this.getProfile();
              } else {
                this.$toast.error(response.message)
                this.enableerrmessage = true;
                this.errormessage = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enableerrmessage = false), 3000);
              }
            });
        } else {
          this.$toast.error("Please enter required field")
          return;
        }
      });
    },
    verifyEmail() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          this.verify_email.source = 'web';
          this.verify_email.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
          this.$store.dispatch("verifyEmailAddress", this.verify_email).then(response => {
            this.loading = false;
            if (response.status == 1) {
              bus.$emit('timer', 60);
              window.$("#login-form").modal("show");
              window.$("#VerificationFields").modal("show");
              window.$('.animated-form').find(".fieldset").eq(0).hide();
            }
          });
        } else {
          this.$toast.error("Please enter required field");
          return;
        }
      })
    },
    closePopup() {
      window.$('.modal-backdrop').hide();
    },
    editProfile() {
      this.getProfile();
    },

    myFunction(){
      console.log("data");
      window.$("#unverifyemail").hide();
      
    }
  }
};
</script>
<style>
.hide_file {
  background-color: #24c7fa;
  background-image: url("../../assets/images/location-icon.png");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.fileuplderr {
  display: none;
  margin: 1px 40px -1px -40px;
  width: 100%;
}

.profile-section .profile-photo-changing.photo-delete{
    right: 35px;
    position: absolute;
    bottom: 10px;
    /* top: auto; */
}
.edit-img-button button{
  border: 0;
  border-radius: 5px;
}
</style>